.content__product {
  .product__category {
    font-size: 10px;
    line-height: 12px;
    letter-spacing: 0.03em;
    text-transform: uppercase;
    color: #8f9297;
    margin-top: 20px;
    margin-bottom: 10px;
  }

  .advantages_ul {
    display: flex;
    flex-wrap: wrap;
    padding: 0;

    li {
      list-style: none;
      width: 50%;
      @include flex($content: flex-start, $align: center, $direction: row);
      font-weight: 700;
      font-size: 15px;
      line-height: 18px;
      color: #eaecef;
      margin-bottom: 25px;
      padding-right: 10px;
    }
    img {
      margin-right: 12px;
    }
  }

  .product__price {
    font-weight: 700;
    font-size: 25px;
    line-height: 30px;
    color: #eaecef;
  }

  .product__add-to-cart {
    margin-right: 10px;
  }

  .product__footer {
    @include flex($content: space-between, $align: center, $direction: row);
  }

  .product__h2.h2 {
    font-weight: 700;
    font-size: 40px;
    line-height: 48px;
    color: #eaecef;
    margin-bottom: 30px;
  }

  .product__actions > a:first-child {
    margin-right: 10px;
  }
}

@include media-tablet-small-max() {
  .content__product {
    .product__price {
      float: none;
      margin-bottom: 10px;
    }
    .product__btn {
      margin-bottom: 10px;
      margin-right: 0;
    }
    .product__actions {
      margin-bottom: -10px;
    }
  }
}
