.news__tabs {
  overflow-x: auto;
  justify-content: flex-start !important;

  .tabs__item {
    flex-shrink: 0;
  }
}

.news__content {
  width: 100%;
  align-self: center;
}

.news__thumbnail {
  margin: 20px 0;
}

.news__meta {
  display: flex;
  align-items: center;
}

.news__footer {
  display: flex;
  justify-content: space-between;
}

.news__readmore {
  display: flex;
  margin-bottom: 5px;
}

.news__list > .news__wrap:last-child {
  margin-bottom: 0;
}

.news__list.sidebar__list.news__two img {
  height: 150px;
  margin-bottom: 10px;
}

.news__two {
  .news__description.description {
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
    max-width: 144px;
  }

  .news__thumbnail img {
    object-fit: cover;
    width: 100%;
    height: 100px;
  }

  .news__thumbnail {
    margin: 0 10px 0 0;
    padding: 0;
    flex: none;
    max-width: 210px;
    width: 210px;
    align-self: center;
    margin-left: auto;
  }

  .news__wrap {
    display: flex;
    margin-bottom: 20px;
    height: calc(100% - 20px);
    position: relative;
    border-bottom: 1px solid #2d3d52;
  }

  .news__h2.h2 {
    font-size: 15px;
    line-height: 18px;
    color: #eaecef;
    margin-top: 15px;
  }

  .news__footer {
    display: block;
    margin-top: 7px;
  }
}

.main__sidebar {
  .news__two .news__h2.h2 {
    margin-top: 0;
  }

  .news__readmore a {
    width: 100%;
    text-align: center;
    margin-top: 5px;
  }

  .news__footer,
  .news__wrap {
    margin-bottom: 10px;
  }

  .news__readmore {
    position: initial;
    bottom: 20px;
  }
}

.tags__ul {
  display: flex;
  align-items: center;
  list-style: none;
  margin: 0;
  padding: 0;
}

.tags__li {
  margin-right: 7px;
  display: flex;
}

.tags__a {
  font-size: 10px;
  line-height: 12px;
  color: #eaecef;
  padding: 5px 10px;
  background: #455580;
  border-radius: 10px;
}

.tabs__a {
  width: 100%;
  padding: 0px 8px;
  font-size: 12px;
  line-height: 15px;
  letter-spacing: 0.03em;
  text-transform: uppercase;
  color: #8f9297;
  border-bottom: 1px solid #434651;
  padding-bottom: 8px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: transparent;
  border: 0;
  cursor: pointer;
}

.tabs__item.tabs__active .tabs__a,
.tabs__item:hover .tabs__a {
  border-bottom: 1px solid #0072ff;
  color: #eaecef;
}
