@include media-tablet-large-min-max() {
  .content__statistics {
    > div > div {
      -webkit-box-flex: 0;
      -ms-flex: 0 0 100%;
      flex: 0 0 100%;
      max-width: 100%;
    }

    .statistics__chart.content__frame {
      margin-bottom: 10px;
    }

    .col-md-4 {
      order: 1;
    }

    .qualification_img img {
      height: auto;
    }
  }

  .content__qualification {
    &.content__frame {
      margin-bottom: 10px;
    }

    .col-md-9 {
      -webkit-box-flex: 0;
      -ms-flex: 0 0 60%;
      flex: 0 0 60%;
      max-width: 60%;

      .col-md-4 {
        -webkit-box-flex: 0;
        -ms-flex: 0 0 50%;
        flex: 0 0 50%;
        max-width: 50%;
      }

      .col-md-4:last-child {
        -webkit-box-flex: 0;
        -ms-flex: 0 0 50%;
        flex: 0 0 50%;
        max-width: 50%;
      }
    }
  }
}
@include media-tablet-small-max() {
  .content__qualification {
    .col-md-9 {
      .col-md-4 {
        -webkit-box-flex: 0;
        -ms-flex: 0 0 50%;
        flex: 0 0 50%;
        max-width: 50%;
      }

      .col-md-4:last-child {
        -webkit-box-flex: 0;
        -ms-flex: 0 0 50%;
        flex: 0 0 50%;
        max-width: 50%;
      }
    }
  }
}
