.support__wrap {
  display: flex;
  height: calc(100% - 30px);
}

.support__thumbnail.main__thumbnail {
  flex: none;
  margin-right: 20px;
}

.support__questions {
  width: 100%;
}

.search-form {
  height: 95px;
}

.support-search-background-img {
  position: absolute;
  top: 0;
  left: 50%;
  margin-left: -25%;
  z-index: 0;
  height: 375px;
}

@media (max-width: 1199px) {
  .support-search-background-img {
    left: 25%;
  }
}

@include media-tablet-large-min-max() {
  .support-search-background-img {
    left: 25%;
  }

  .support__wrap {
    height: calc(100% - 10px);
  }

  .support__thumbnail.main__thumbnail img {
    max-width: 185px;
  }
  h3.support__h3.h3 {
    font-size: 16px;
    line-height: normal;
  }

  .support__wrap {
    display: block !important;
  }

  div:not(.support__contact) > .support__thumbnail {
    text-align: center;
    background: #fff;
    border-radius: 20px;
    margin-bottom: 20px;
  }
}

@include media-tablet-small-max() {
  .support-search-background-img {
    height: 355px;
  }

  .support__search img {
    object-fit: cover;
    border-radius: 20px;
  }

  .support__wrap {
    display: block !important;
  }

  .support__wrap {
    height: auto;
  }

  .support__search {
    border-radius: 20px;
  }

  .support__thumbnail.main__thumbnail img {
    max-width: 120px;
    height: auto;
  }

  h3.support__h3.h3 {
    font-size: 16px;
    line-height: normal;
  }

  div:not(.support__contact) > .support__thumbnail {
    text-align: center;
    background: #fff;
    border-radius: 20px;
    margin-bottom: 20px;
  }
}
