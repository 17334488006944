

.content__partner {
  .partner__wrap {
    @include flex($content: flex-start, $align: flex-start, $direction: column);
    max-width: 100%;
    padding-right: 20px;
  }

  .partner__marketing {
    @include flex($content: space-between, $align: flex-start, $direction: row);
  }
  .partner__h3.h3 {
    margin-bottom: 20px;

    .partner__h3 a img {
      transform: rotate(180deg);
      margin-left: 30px;
    }

    .partner__h3 a {
      font-size: 20px;
      line-height: 24px;
      color: #0072ff;
      display: flex;
      align-items: center;
    }
  }
  .filter-submit button {
    height: 40px;
    padding: 0 17px;
    margin-top: 25px;
    border: 1px solid #2d3d52;
  }
  .partner__description {
    font-size: 15px;
    line-height: 18px;
    color: #eaecef;
  }

  .partner__btn.btn {
    display: block;
    margin-top: auto;
  }

  .partner__h5.h5 {
    margin-bottom: 20px;
    text-transform: uppercase;
    margin-top: 0;
    line-height: 18px;
    letter-spacing: 0.03em;
    font-size: 15px;
  }
  .partner__h6.h6 {
    margin-top: 0;
    margin-bottom: 10px;
  }
  .partner__img {
    flex: none;
  }

  .partner__academy {
    height: calc(100% - 30px);
    @include flex($content: flex-start, $align: flex-start, $direction: column);

    img {
      position: absolute;
      top: 0;
      right: 15px;
      z-index: 0;
    }
  }
  .partner__calculator {
    @include flex($content: flex-start, $align: flex-start, $direction: row);

    img {
      border-radius: 15px;
    }

    .partner__wrap {
      margin-left: 20px;
    }
  }
  .tree__card {
    @include flex($content: space-between, $align: flex-end, $direction: row);
    padding: 20px;
    border-bottom: 1px solid #2d3d52;

    .tree__row {
      padding-left: 30px;
      min-width: 975px;
      border-left: 1px solid #455580;
      position: relative;

      .tree__div {
        .partner__h3.h3 {
          letter-spacing: 0.03em;
          text-transform: uppercase;
          margin-bottom: 0;
          margin-top: 10px;
        }

        .tree__div .partner__h5.h5 {
          margin: 0;
        }
      }
    }

    .tree__row:last-child > .tree__card:last-child:after {
      @include flex($content: flex-start, $align: flex-start, $direction: row);
      height: 52px;
      width: 10px;
      position: absolute;
      background: #101316;
      content: '';
      left: -7px;
      top: 67px;
    }

    .tree__row:before {
      width: 15px;
      height: 15px;
      background: #064da6;
      border-radius: 100px;
      content: '';
      @include flex($content: flex-start, $align: flex-start, $direction: row);
      border: 4px solid #0072ff;
      position: absolute;
      left: -7px;
      top: 52px;
    }

    img {
      transform: rotate(270deg);
      margin: 10px;
    }
  }

  .tree__card + .tree__row:after {
    @include flex($content: flex-start, $align: flex-start, $direction: row);
    height: 52px;
    width: 10px;
    position: absolute;
    background: #101316;
    content: '';
    left: -7px;
    top: 0;
  }

  .tree__wrap::-webkit-scrollbar {
    width: 20px; /* ширина scrollbar */
  }

  .tree__wrap::-webkit-scrollbar-track {
    background: transparent; /* цвет дорожки */
  }

  .tree__wrap::-webkit-scrollbar-thumb {
    background-color: #0072ff; /* цвет плашки */
    border-radius: 20px; /* закругления плашки */
    border: 5px solid #101316; /* padding вокруг плашки */
    &:hover{
      background-color: #0062D0C6;
      cursor: pointer !important;
    }
  }
}

.promo__wrap {
  text-align: center;
  padding: 34px;

  .partner__h3.h3 {
    margin-bottom: 0;
  }

  img {
    margin-bottom: 10px;
    border-radius: 10px;
  }
}

.marketing__wrap {
  margin-bottom: 30px;

  .partner__referral {
    margin-bottom: 0;
  }

  .marketing__h1.h1 {
    margin-top: 0;
  }

  .marketing__h3.h3 {
    font-weight: 700;
    font-size: 15px;
    line-height: 18px;
  }

  .marketing__header {
    @include flex($content: space-between, $align: flex-start, $direction: row);
    margin-bottom: -10px;
  }

  .marketing__list {
    margin-top: 30px;
  }

  .marketing__item-picture {
    margin-right: 0 !important;
  }

  .marketing__img {
    flex: none;
  }

  .marketing__btn.btn {
    width: 100%;
    @include flex($content: center, $align: flex-start, $direction: row);
  }

  .marketing__description.description {
    font-size: 15px;
    line-height: 18px;
    color: #eaecef;
    margin-bottom: 10px;
  }
}

@include media-tablet-large-min-max() {
  .content__partner {
    .col-md-9 {
      -webkit-box-flex: 0;
      -ms-flex: 0 0 75%;
      flex: 0 0 calc(100% - 260px);
      max-width: calc(100% - 260px);
    }

    .col-md-3 {
      -webkit-box-flex: 0;
      -ms-flex: 0 0 25%;
      flex: 0 0 260px;
      max-width: 260px;
    }
    .partner__tree {
      .col-md-3 {
        -webkit-box-flex: 0;
        -ms-flex: 0 0 100%;
        flex: 0 0 100%;
        max-width: 100%;
      }

      .filter-submit button {
        margin-top: 0;
      }
    }

    .partner__marketing {
      .partner__description {
        max-width: calc(100% - 90px);
        margin-bottom: 20px;
      }

      .partner__img {
        z-index: 2;
        flex: none;
        position: absolute;
        right: -60px;
        bottom: -20px;
      }
    }
    .partner__referral {
      .partner__description {
        line-height: normal;
      }
    }

    .partner__academy {
      height: calc(100% - 10px);
    }
  }
}

@include media-tablet-small-max() {
  .content__partner {
    .partner__academy {
      height: auto;
      img {
        top: -24px;
      }
    }

    .filter-submit button {
      margin: 0;
    }

    .partner__calculator {
      display: block;
    }

    .partner__btn.btn {
      margin-top: 20px;
    }

    .partner__academy img {
      max-width: 180px;
      height: auto;
    }

    .tree__card {
      display: block;
    }

    .partner__h3 a {
      font-size: 0;
    }

    .partner__marketing {
      .partner__title {
        font-size: 30px;
        line-height: 1.2;
      }

      .partner__description {
        margin-bottom: 20px;
        min-height: auto;
        font-size: 18px;
        line-height: 1.2;
      }

      .partner__img {
        z-index: 2;
        flex: none;
        position: absolute;
        right: -60px;
        bottom: -20px;
      }
    }
  }
}
