.hidden {
  width: 0 !important;
  height: 0 !important;
  padding: 0 !important;
  margin: 0 !important;
  line-height: 0 !important;
  opacity: 0 !important;
  overflow: hidden !important;
  z-index: 0 !important;
}

a {
  cursor: pointer;
}

p {
  margin-top: 0;
}

.validation-text {
  padding: 0;
  margin: 0;
  margin-top: -10px;
  margin-bottom: 10px;
  font-size: 14px;
  color: red;
}

.error-message {
  color: $error;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  /* display: none; <- Crashes Chrome on hover */
  -webkit-appearance: none;
  margin: 0; /* <-- Apparently some margin are still there even though it's hidden */
}
input[type='number'] {
  -moz-appearance: textfield;
}

::-webkit-scrollbar {
  position: absolute;
  top: 10px;
  height: 10px;
  width: 6px;
  overflow-y: scroll;
}

::-webkit-scrollbar-track {
  position: absolute;
  background-color: #1c2a39;
}

::-webkit-scrollbar-thumb {
  background: #616161;
}

//for p2p layout tabs
.p2p__a.active {
  background: #2d3d52 !important;
  color: #0072ff;
}

.main-header__rate .text-spinner,
.main-header__rate .rate__ul {
  height: 25px;
}
.verify-wrapper {
  display: flex;
  align-items: center;
  position: absolute;
  left: -18px;
}

.text-spinner {
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: center;

  color: white;
  text-align: center;
}

.error-boundary {
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: center;
  gap: 0 10px;
  color: white;

  .error-text {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
}

.rate__ul .initial-child-container,
.rate__ul .marquee {
}

@include media-tablet-large {
  .cart__wrap {
    .cart__name .cart__h4 {
      font-size: 14px;
    }
  }
}

li.account__li .h5,
li.account__li .h6 {
  margin: 0;
}

@include media-tablet-large-min-max() {
  header.main-header {
    .main-header__bottom {
      .main-header__logo {
        width: 100%;
        margin-right: 25px;
      }

      .main-header__account,
      .main-header__burger .main-header__burger_wrapper {
        flex: none;
      }

      .main-header__burger .main-header__burger_wrapper {
        display: none !important;
      }
    }
  }

  .col-1,
  .col-2,
  .col-3,
  .col-4,
  .col-5,
  .col-6,
  .col-7,
  .col-8,
  .col-9,
  .col-10,
  .col-11,
  .col-12,
  .col,
  .col-auto,
  .col-sm-1,
  .col-sm-2,
  .col-sm-3,
  .col-sm-4,
  .col-sm-5,
  .col-sm-6,
  .col-sm-7,
  .col-sm-8,
  .col-sm-9,
  .col-sm-10,
  .col-sm-11,
  .col-sm-12,
  .col-sm,
  .col-sm-auto,
  .col-md-1,
  .col-md-2,
  .col-md-3,
  .col-md-4,
  .col-md-5,
  .col-md-6,
  .col-md-7,
  .col-md-8,
  .col-md-9,
  .col-md-10,
  .col-md-11,
  .col-md-12,
  .col-md,
  .col-md-auto,
  .col-lg-1,
  .col-lg-2,
  .col-lg-3,
  .col-lg-4,
  .col-lg-5,
  .col-lg-6,
  .col-lg-7,
  .col-lg-8,
  .col-lg-9,
  .col-lg-10,
  .col-lg-11,
  .col-lg-12,
  .col-lg,
  .col-lg-auto,
  .col-xl-1,
  .col-xl-2,
  .col-xl-3,
  .col-xl-4,
  .col-xl-5,
  .col-xl-6,
  .col-xl-7,
  .col-xl-8,
  .col-xl-9,
  .col-xl-10,
  .col-xl-11,
  .col-xl-12,
  .col-xl,
  .col-xl-auto {
    padding: 0 5px;
  }

  .row {
    margin: 0 -5px;
  }

  .content__frame {
    // margin-bottom: 10px !important;
  }

  .form__row {
    margin-bottom: 20px;
  }
}

.cart__wrap {
  @include flex($content: space-between, $align: flex-start, $direction: row);
  border: 1px solid #455580;
  padding: 10px;
  border-radius: 10px;
  margin-bottom: 20px;

  &:last-child {
    margin-bottom: 0;
  }

  .cart__img {
    border-radius: 10px;
    overflow: hidden;
    flex: none;
    width: 65px;
    height: 65px;
    margin-right: 10px;

    img {
      object-position: left;
    }
  }

  .h4 {
    margin-bottom: 5px;
    margin-right: 10px;
  }

  .h5 {
    margin: 0;
  }

  .h6 {
    color: #8f9297;
    margin: 0;
    margin-bottom: 5px;
  }

  .cart__name {
    max-width: 300px;
  }

  .cart__actions {
    @include flex($content: flex-start, $align: flex-end, $direction: column);

    .price__row {
      max-width: 105px;
      width: 105px;

      input {
        border-color: transparent;
        font-size: 12px;
        margin: 0;
      }
    }

    .cart__remove {
      margin-bottom: 10px;
      &:hover {
        cursor: pointer;
      }
    }
  }
}

@include media-tablet-small-max() {
  time {
    font-size: 12px !important;
  }

  .modal__content {
    max-width: calc(100vw - 20px) !important;
    position: absolute !important;
    top: 10% !important;
    // bottom: 5%!important;
  }

  .marketing-popup .modal__content {
    max-height: 100vh !important;
  }

  .content__frame {
    margin-bottom: 10px !important;
  }

  a.account__li.header-avatar {
    font-size: 0;
  }

  .header-avatar {
    gap: 0;
  }

  .account__div {
    max-width: calc(100% - 10px);
    width: 100%;
    //left: 5px;
    right: 5px;
  }

  .account__div.cart-window,
  .account__div.notification-window {
    right: 5px !important;
  }

  .account__li {
    .cart-icon {
      position: initial;
    }
  }

  .menu__collapse .col-md-7,
  .menu__collapse .col-md-10 {
    max-width: 100% !important;
    flex: 1 1 100% !important;
  }

  ul.account__ul {
    gap: 0 10px;
  }

  .main-header__account,
  .main-header__burger .main-header__burger_wrapper {
    flex: none;
  }

  .header-avatar {
    gap: 0;
  }

  .col-1,
  .col-2,
  .col-3,
  .col-4,
  .col-5,
  .col-6,
  .col-7,
  .col-8,
  .col-9,
  .col-10,
  .col-11,
  .col-12,
  .col,
  .col-auto,
  .col-sm-1,
  .col-sm-2,
  .col-sm-3,
  .col-sm-4,
  .col-sm-5,
  .col-sm-6,
  .col-sm-7,
  .col-sm-8,
  .col-sm-9,
  .col-sm-10,
  .col-sm-11,
  .col-sm-12,
  .col-sm,
  .col-sm-auto,
  .col-md-1,
  .col-md-2,
  .col-md-3,
  .col-md-4,
  .col-md-5,
  .col-md-6,
  .col-md-7,
  .col-md-8,
  .col-md-9,
  .col-md-10,
  .col-md-11,
  .col-md-12,
  .col-md,
  .col-md-auto,
  .col-lg-1,
  .col-lg-2,
  .col-lg-3,
  .col-lg-4,
  .col-lg-5,
  .col-lg-6,
  .col-lg-7,
  .col-lg-8,
  .col-lg-9,
  .col-lg-10,
  .col-lg-11,
  .col-lg-12,
  .col-lg,
  .col-lg-auto,
  .col-xl-1,
  .col-xl-2,
  .col-xl-3,
  .col-xl-4,
  .col-xl-5,
  .col-xl-6,
  .col-xl-7,
  .col-xl-8,
  .col-xl-9,
  .col-xl-10,
  .col-xl-11,
  .col-xl-12,
  .col-xl,
  .col-xl-auto {
    padding: 0 5px;
  }

  .row {
    margin: 0 -5px;
  }
}

@include media-tablet-small-max() {
  .cart__wrap .cart__name {
    max-width: 250px;
  }
}
