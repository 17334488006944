main.landing {
}

.mainmenu__ul {
  .main-header__btn {
    display: none;
  }
}

.slider__footer.landing-form {
  display: none;

  margin-top: 10px;
  border-radius: 20px;
  background: var(--title-gradient, linear-gradient(150deg, #0072ff 0%, #064da6 99.17%));
  // height: 200px;

  padding: 15px;
  width: 100%;

  .form__row input {
    background: transparent;
    color: white;

    &::placeholder {
      color: white;
    }
  }
}

@include media-desktop() {
  .main-header__mainmenu .mainmenu__nav {
    .mainmenu__a {
      @include adaptive-value('font-size', 16, 12, $desktop-width, 1200);
    }
  }
}

@media (max-width: 1333px) {
  .main-header__mainmenu nav.mainmenu__nav {
    transform: translate(100vw);
    transition: top 0s, transform 0.3s;
  }

  .main-header__mainmenu nav.mainmenu__nav.burger-active {
    transform: translate(10px);
    transition: top 0s, transform 0.3s;
  }
  .main-header__bottom .main-header__burger .main-header__burger_wrapper {
    display: flex !important;
  }

  .main-header.main-header__landing nav.mainmenu__nav .mainmenu__a {
    color: #eaecef;
    font-size: 21px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    display: block;
    margin-bottom: 30px;
  }
  .main-header.main-header__landing nav.mainmenu__nav ul {
    display: block;
  }
  .main-header.main-header__landing nav.mainmenu__nav {
    position: fixed;
    right: 0;
    top: 109px;
    padding: 50px;
    background: #0072ff;
    height: 100vh;
    transition: top 0s, transform 0.3s;
  }
}

@include media-tablet-large() {
  .slider-three {
    padding: 40px !important;
    &__text {
      .slider-three__title {
        @include adaptive-value('font-size', 40, 20, $tablet-large-width, $mobile-width);
        text-align: center;
        margin: 0 auto !important;
      }
      .slider-three__subtitle {
        @include adaptive-value('font-size', 25, 16, $tablet-large-width, $mobile-width);
        text-align: center;
        white-space: nowrap;
      }
    }
  }
}

@media (max-width: 767px) {
  .lk-header.main-header {
    height: 125px !important;

    .account__div {
      top: 69px;
    }
  }

  .main-header__bottom .main-header__burger .main-header__burger_wrapper {
    display: flex !important;
    justify-content: center;
    align-items: center;
    flex-direction: column;
  }

  .main-header__logo {
    margin-left: 5px;
    margin-right: auto;

    img {
      width: 87px;
      object-position: left;
    }
  }

  .main-header__sidebar {
    display: none !important;
  }

  .mainmenu__ul {
    .main-header__btn {
      display: block;
      background-color: #eaecef;
      margin-bottom: 30px;

      &.registration-btn {
        color: black;
        margin-bottom: 10px;
      }
    }
  }

  .slider-two {
    &__text {
      .slider-two__title {
        @include adaptive-value('font-size', 40, 20, $tablet-small-width, $mobile-width);
      }
    }
  }
}

@media (max-width: 767px) {
  .slider-two {
    @include adaptive-value('row-gap', 30, 50, $tablet-small-width, $mobile-width);

    & &__text {
      @include adaptive-value('row-gap', 30, 50, $tablet-small-width, $mobile-width);
    }

    & &__image {
      @include adaptive-value('width', 250, 200, $tablet-small-width, $mobile-width);
      margin: 0;
      height: auto;
    }
  }
}
