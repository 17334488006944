.profile__2fa-status {
  @include flex();
}

@include media-tablet-large-min-max() {
  .content__profile {
    .col-md-7,
    .col-md-5 {
      -webkit-box-flex: 0;
      -ms-flex: 0 0 100%;
      flex: 0 0 100%;
      max-width: 100%;
    }
  }
}

@include media-tablet-small-max() {
  .referral__row {
    flex-direction: column;
    align-items: flex-start !important;

    .referral__url {
      max-width: 100%;
    }
  }

  .profile__operations {
    flex-wrap: wrap;

    .checkbox {
      margin-bottom: 25px;
    }

    .label:nth-last-child(2) {
      width: auto;
      margin-right: 5px;
    }

    .label {
      width: calc(100% - 35px);
      margin-right: 0;
      margin-bottom: 25px;
    }

    .profile__conect {
      margin-bottom: 23px;
    }
  }
}
