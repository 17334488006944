$desktop-width: 1440;
$tablet-large-width: 992;
$tablet-small-width: 768;
$mobile-width: 375;
$size-md: 800px;

$tablet-large-width-max: 1199;
$tablet-large-width-min: 768;
$tablet-small-width-max: 767;

// colors

$bg-cart: #1d2938;
$bg-button-primary:#0072ff;
$bg-button-secondary:#a5a5a5;
$error : #c40000;
// shadow

$modal-shadow: rgba(0, 0, 0, 0.25) 0 54px 55px, rgba(0, 0, 0, 0.12) 0px -12px 30px, rgba(0, 0, 0, 0.12) 0px 4px 6px, rgba(0, 0, 0, 0.17) 0px 12px 13px, rgba(0, 0, 0, 0.09) 0px -3px 5px;