.p2p__frame-wrap .p2p__list-h6.h6 span {
  margin-left: 10px;
  display: flex;
  align-items: center;
  flex: none;
}

@include media-tablet-large-min-max() {

    .content__p2p {

        .p2p__frame-wrap {

            

            .p2p__frame-item {
                height: 119px;
            }

            .p2p__list-h6.h6 {
                font-size: 9px;
            }

            .p2p__frame-action { 
        
                span.h5__badge {
                    margin-right: 5px;
                    margin-bottom: 5px;
                }
            }
        } 
    }
}

@include media-tablet-small-max() {

    .content__p2p {

        .p2p__card-details {
            display: block;
        }

        .p2p__card-avatar {
            position: absolute;
        }

        .p2p__card-bio {

            .p2p__list-name {
                margin-left: 50px;
                margin-bottom: 25px;
                margin-top: 10px;
            }
        }

        .p2p__card-ul {
            display: block;

            .p2p__card-li {
                border: 0;
                margin-bottom: 5px;
            }
        }

        .p2p__card-reviews {
            max-width: 100%;
            margin: 0;
        }

        .p2p__frame-item {
            margin-bottom: 10px;
        }

        .p2p__list {
            
            .p2p__li a {
                padding: 5px 15px;
            }
        }
    }

}
