.custom-select {
  &__control {
    padding-top: 2px !important;
    padding-bottom: 2px !important;

    border-radius: 10px !important;
    background-color: transparent !important;
    border: 1px solid #2d3d52 !important;

    &--is-focused {
      box-shadow: none !important;
      border-color: #2684ff !important;
    }
  }

  &__value-container {
    input {
      border: none !important;
      border-radius: 0 !important;
      padding: 0 !important;
      width: 0 !important;
      margin-bottom: 0 !important;
      outline: none !important;
      background: none !important;
      color: transparent !important;
    }
  }

  &__single-value {
    color: #eaecef !important;
  }

  &__indicator {
    color: #eaecef !important;
    cursor: pointer;
  }

  &__indicator-separator {
    display: none !important;
  }

  &__menu {
    color: #eaecef !important;
    background-color: #2d3d52 !important;
  }

  &__option {
  }

  &__option--is-focused {
    background-color: #576980 !important;
  }
}

.modal__wrap {
  .custom-select {
    &__control {
      border: 1px solid #455580 !important;

      &--is-focused {
        box-shadow: none !important;
        border-color: #2684ff !important;
      }
    }
  }
}

.modal__content .form__row input {
  height: auto !important;
}

.landing-chose-type-2fa {
  .custom-select {
    &__single-value {
      color: black !important;
    }

    &__indicator {
      color: black !important;
      cursor: pointer;
    }

    &__menu {
      color: black !important;
      background-color: #eaecef !important;
    }

    &__option--is-focused {
      background-color: #cbcdcf !important;
    }
  }
}
