.content__finance {
  .finance__header {
    @include flex($content: space-between, $align: center, $direction: row);
    padding-bottom: 10px;
    margin-bottom: 10px;
    border-bottom: 1px solid #2d3d52;
  }

  .finance__h1 {
    margin: 0;
  }

  .finance__wrap {
    @include flex($content: space-between, $align: flex-start, $direction: row);
    border-radius: 10px;
    padding: 10px;
    margin-bottom: 10px;

    .finance__status {
      font-weight: 700;
      font-size: 8px;
      line-height: 10px;
      color: #eaecef;
      background: #2d3d52;
      display: inline-block;
      padding: 5px 10px;
      border-radius: 10px;
      text-transform: none;
    }

    .finance__div {
      margin-bottom: 5px;
    }
  }
}

.finance__balance {
  .balance__footer {
    span {
      width: 50%;
      display: flex;
      align-items: center;

      img {
        margin: 0 5px;
      }

      img:first-child {
        margin-left: 0;
      }
    }
    > div {
      @include flex($content: space-between, $align: flex-start, $direction: row);
    }
  }

  .balance__div {
    margin-bottom: 10px;
  }
}

.finance__btn.btn {
  font-weight: 700;
  @include adaptive-value("font-size", 16, 14, 1920, 320);
  line-height: normal;
  color: #eaecef;
  padding: 10px 0;
}

@include media-tablet-small-max() {
  .transactions-history-container {
    order: 3;
  }

  .finance-container {
    order: 2;
  }
}

.filter-remove {
  margin-left: auto;
  margin-right: 10px;
}
