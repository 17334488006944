@mixin flex($content: flex-start, $align: center, $direction: row) {
  display: flex;
  justify-content: $content;
  align-items: $align;
  flex-direction: $direction;
}

@mixin adaptive-value($property, $startSize, $minSize, $maxWidth, $minWidth) {
  $addSize: $startSize - $minSize;
  #{$property}: calc(#{$minSize + px} + #{$addSize} * ((100vw - #{$minWidth + px}) / #{$maxWidth - $minWidth}));
}

@mixin media-desktop() {
  @media (max-width: #{$desktop-width + px}) {
    @content;
  }
}

@mixin media-tablet-large() {
  @media (max-width: #{$tablet-large-width + px}) {
    @content;
  }
}

@mixin media-tablet-large-min-max() {
  @media (min-width: #{$tablet-large-width-min + px}) and (max-width: #{$tablet-large-width-max + px}) {
    @content;
  }
}

@mixin media-tablet-large-min-1470() {
  @media (min-width: #{$tablet-large-width-min + px}) and (max-width: #{1470 + px}) {
    @content;
  }
}

@mixin media-tablet-small() {
  @media (max-width: #{$tablet-small-width + px}) {
    @content;
  }
}

@mixin media-tablet-small-max() {
  @media (max-width: #{$tablet-small-width-max + px}) {
    @content;
  }
}

@mixin media-mobile() {
  @media (max-width: #{$mobile-width + px}) {
    @content;
  }
}

$desktop-width: 1440;
$tablet-large-width: 992;
$tablet-small-width: 768;
$mobile-width: 375;
$size-md: 800px;

$tablet-large-width-max: 1199;
$tablet-large-width-min: 768;
$tablet-small-width-max: 767;

// colors

$bg-cart: #1d2938;
$bg-button-primary:#0072ff;
$bg-button-secondary:#a5a5a5;
$error : #c40000;
// shadow

$modal-shadow: rgba(0, 0, 0, 0.25) 0 54px 55px, rgba(0, 0, 0, 0.12) 0px -12px 30px, rgba(0, 0, 0, 0.12) 0px 4px 6px, rgba(0, 0, 0, 0.17) 0px 12px 13px, rgba(0, 0, 0, 0.09) 0px -3px 5px;
@keyframes rotate {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

@-webkit-keyframes rotate {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.load {
  min-width: 30px;
  min-height: 30px;
  border: solid 2px #0072ff;
  border-radius: 50%;
  border-right-color: transparent;
  border-bottom-color: transparent;
  transition: all 0.5s ease-in;
  animation-name: rotate;
  animation-duration: 1s;
  animation-iteration-count: infinite;
  animation-timing-function: linear;
}

.loader {
  position: fixed;
  inset: 0;
  display: grid;
  place-items: center;
  background-color: #1d2938;
  z-index: 500;
  svg {
    width: 120px;
  }
}
.loader.light {
  background: #fff;
}
.loader__wrapper img {
  width: 86px;
}
.lds {
  position: fixed;
  top: 50%;
  z-index: 100;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 180px;
  height: 180px;
}
.lds-ring {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 180px;
  height: 180px;
}
.lds-ring div {
  position: absolute;
  top: 0;
  left: 0;
  width: 180px;
  height: 180px;
  border: 2px solid #0072ff;
  border-radius: 50%;
  animation: lds-ring 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
  border-color: #0072ff transparent transparent transparent;
  z-index: 250;
}
.lds-ring div:nth-child(1) {
  animation-delay: -0.45s;
}
.lds-ring div:nth-child(2) {
  animation-delay: -0.3s;
}
.lds-ring div:nth-child(3) {
  animation-delay: -0.15s;
}

.loader-module {
  position: absolute;
  inset: 0;
  display: grid;
  place-items: center;
  background-color: #1d2938;
  z-index: 500;
  &_lds {
    position: absolute;
    top: 50%;
    z-index: 100;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 180px;
    height: 180px;
  }
  &_lds-ring {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 180px;
    height: 180px;
  }
  &_lds-ring div {
    position: absolute;
    top: 0;
    left: 0;
    width: 180px;
    height: 180px;
    border: 2px solid #0072ff;
    border-radius: 50%;
    animation: lds-ring 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
    border-color: #0072ff transparent transparent transparent;
    z-index: 250;
  }
  &_lds-ring div:nth-child(1) {
    animation-delay: -0.45s;
  }
  &_lds-ring div:nth-child(2) {
    animation-delay: -0.3s;
  }
  &_lds-ring div:nth-child(3) {
    animation-delay: -0.15s;
  }
}
.loader-module__wrapper img {
  width: 56px;
}

@keyframes lds-ring {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
