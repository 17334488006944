.p2p__buy-actions {
  @include flex();
  flex-wrap: wrap;
  gap: 10px 20px;

  .p2p__buy__btn.background__none {
    padding-left: 0;
    padding-right: 0;
  }
}

.p2p__buy-payment-detail {
  .p2p__h5.h5.cart-number {
    .referral__a {
      margin-left: 0;
    }
  }
}

@include media-tablet-large-min-max() {
  .p2p__buy-wrap {
    position: relative;

    .col-md-4,
    .col-md-8 {
      -webkit-box-flex: 0;
      -ms-flex: 0 0 100%;
      flex: 0 0 100%;
      max-width: 100%;
      position: initial;
    }

    .p2p__info_order-page {
      position: absolute;
      top: 0;
      right: 0px;

      .p2p__h6.h6 {
        font-size: 10px;
      }
    }

    .p2p__buy-step {
      margin-bottom: 50px;

      .p2p__h3.h3 {
        display: flex;
        align-items: center;
        margin-bottom: 20px;
      }
    }
  }
}

@include media-tablet-small-max() {
  .p2p__buy-payment-detail {
    .p2p__h5.h5.cart-number {
      font-size: 12px;
    }
  }

  .p2p__buy-wrap {
    position: relative;

    .col-md-4,
    .col-md-8 {
      position: initial;
    }

    .p2p__info_order-page {
      .p2p__h6.h6 {
        font-size: 10px;
      }
    }

    .p2p__buy-step {
      margin-bottom: 50px;

      .p2p__h3.h3 {
        display: flex;
        align-items: center;
        margin-bottom: 20px;
      }
    }

    .p2p__buy-price-item {
      margin-right: 0;
    }

    .p2p__buy-price {
      justify-content: space-between;
    }
  }
}
