.no-scroll {
  max-height: fit-content !important;
  overflow-y: unset;
}

.content__cart {
  .cart__h1.h4 {
    text-transform: unset;
    margin: 0;
    margin-bottom: 20px;
  }

  .cart__wrap.cart-total {
    @include flex(space-between, center, row);
    border: 0;
    margin-bottom: 20px;
  }

  .cart__pay {
    .cart__h6.h6 {
      margin-top: 20px;
      margin-bottom: 10px;
    }

    .cart__h5.h5 {
      margin: 0;
    }

    .cart__h5.h5.cart__balance {
      margin-bottom: 20px;
    }

    .h4 {
      margin: 0;
    }
  }

  .cart__list {
    .cart__wrap {
      .cart__name {
        flex-grow: 1;
        max-width: none;
      }
      .cart__actions {
        .form__row.price__row {
          margin: 0;
        }
      }
    }
  }
}

.cart__add-modal .modal__content {
  max-width: 320px;
}

@include media-tablet-small-max() {
  .cart__list .cart__wrap .cart__name {
    max-width: 250px;
  }
}

@include media-tablet-large-min-max() {
  .col-md-7.md__content.cart-page-content {
    max-width: calc(60% - 40px);
    flex-basis: calc(60% - 40px);
  }
  .col-md-3.md__sidebar.cart-page-sidebar {
    max-width: calc(40% - 40px);
    flex-basis: calc(40% - 40px);
  }
}
