.main-header__burger .main-header__burger_wrapper .burger__line,
.main-header__burger .main-header__burger_wrapper::before,
.main-header__burger .main-header__burger_wrapper::after {
  content: '';
  display: block;
  width: 100%;
  height: 2px;
  background-color: #fff;
}
.col-md-2.md__menu {
  -webkit-box-flex: 0;
  -ms-flex: 0 0 260px;
}
.menu__collapse .col-md-2.md__menu {
  -webkit-box-flex: 0;
  -ms-flex: 0 0 110px;
  flex: 0 0 110px;
  max-width: 110px;
}
.md__content {
  max-width: 100%;
  flex: 1 1 100%;
}
.main__wrap > div > .row {
  flex-wrap: nowrap;
}
.main__sidebar.aside-menu {
  position: fixed;
  z-index: 100;
  top: 130px;
  max-height: 648px;
  height: calc(100vh - 110px - 50px);
  margin-left: 0 !important;
  border-radius: 20px;

  box-shadow: rgba(0, 0, 0, 0.3) 0px 19px 38px, rgba(0, 0, 0, 0.22) 0px 15px 12px;

  .sidebar__menu {
    margin-bottom: 0 !important;
    height: 100%;

    & .menu__nav {
      height: 100%;

      & .menu__ul {
        overflow-y: auto;
        height: 100%;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
      }
    }

    .menu__a {
      img {
        object-fit: contain;
      }
    }
  }

  .main__sidebar .news__two .news__h2.h2 {
    margin-top: 0;
  }
  .news__readmore a {
    width: 100%;
    text-align: center;
    margin-top: 20px;
  }

  .main__sidebar .news__footer,
  .main__sidebar .news__wrap {
    margin-bottom: 10px;
  }
}

.main__wrap.menu__collapse {
  .main__sidebar.aside-menu {
    min-width: 80px;
    width: 80px;
  }
}

@include media-tablet-large-min-max() {
  .col-md-2.md__menu {
    flex: 0 0 90px;
    max-width: 90px;
  }

  .main__sidebar.aside-menu {
    min-width: 80px;
    width: 80px;
  }

  .main__wrap li.menu__li a {
    font-size: 0;
  }

  .col-md-7.md__content,
  .col-md-3.md__sidebar {
    max-width: calc(50% - 40px);
    flex: 1 1 calc(50% - 40px);
  }

  .col-md-10.md__content {
    max-width: calc(100% - 90px);
    flex: 1 1 calc(100% - 90px);
  }

  article.main__wrap.menu__collapse {
    .menu__a {
      font-size: 12px;
    }

    .main__sidebar.aside-menu {
      min-width: 230px;
      width: 230px;
    }
  }
}

@include media-tablet-small-max() {
  .md__menu {
    left: -100vw;
    transition: left 0.3s;

    & .main__sidebar.aside-menu {
      left: -100vw;
      transition: left 0.3s;
    }
  }

  .md__menu.burger-menu-active {
    left: 0;
    z-index: 100;
    transition: left 0.3s;

    & .main__sidebar.aside-menu {
      left: 10px;
      transition: left 0.3s;
    }
  }

  .main__wrap > div > .row {
    flex-wrap: wrap;
  }
}
