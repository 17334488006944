.p2p__cell-head {
  display: none;
}

.orders-list__order-info {
  margin: 0 !important;
  padding: 0 !important;
  height: auto !important;
}

.orders__main .p2p__info.orders-list__order-info {
  border-bottom: 1px solid #2d3d52;
  margin-bottom: 20px !important;
}

.p2p__link-to-order {
  padding: 20px 5px !important;
}

@include media-tablet-large-min-max() {
  .orders__main {
    .p2p__list {
      .orders__main .p2p__info {
        height: auto;
      }
      .p2p__list-row.orders__head {
        .p2p__list-h6.h6 {
          font-size: 10px;
        }
      }

      .p2p__list-row {
        gap: 0 10px;
      }
    }
  }
}

@include media-tablet-small-max() {
  .orders__main {
    .p2p__list {
      .p2p__list-row.orders__head {
        display: none;
      }

      .p2p__list-row {
        flex-wrap: wrap;
        padding-bottom: 5px;
        gap: 10px 5px;

        .p2p__list-div {
          position: relative;
          padding-top: 20px;

          flex: 0 0 33.3%;
          max-width: 32%;
          display: flex;
          overflow: hidden;

          .p2p__cell-head {
            display: block;
            position: absolute;
            top: 0;
            left: 0;
            font-size: 10px;
          }
        }

        .p2p__link-to-order {
          padding: 10px 5px !important;
        }

        .order-user-info {
          flex-direction: column;
        }

        .p2p__list-div:last-child {
          flex: 0 0 100%;
          max-width: 100%;
          text-align: center;
          padding-top: 0px;
          padding-bottom: 5px;
        }
      }

      .orders-list__order-info {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        justify-content: flex-start;
      }
    }

    .p2p__filter-bottom {
      .col-md {
        margin-bottom: 20px;
      }
    }
  }
}
