@mixin flex($content: flex-start, $align: center, $direction: row) {
  display: flex;
  justify-content: $content;
  align-items: $align;
  flex-direction: $direction;
}

@mixin adaptive-value($property, $startSize, $minSize, $maxWidth, $minWidth) {
  $addSize: $startSize - $minSize;
  #{$property}: calc(#{$minSize + px} + #{$addSize} * ((100vw - #{$minWidth + px}) / #{$maxWidth - $minWidth}));
}

@mixin media-desktop() {
  @media (max-width: #{$desktop-width + px}) {
    @content;
  }
}

@mixin media-tablet-large() {
  @media (max-width: #{$tablet-large-width + px}) {
    @content;
  }
}

@mixin media-tablet-large-min-max() {
  @media (min-width: #{$tablet-large-width-min + px}) and (max-width: #{$tablet-large-width-max + px}) {
    @content;
  }
}

@mixin media-tablet-large-min-1470() {
  @media (min-width: #{$tablet-large-width-min + px}) and (max-width: #{1470 + px}) {
    @content;
  }
}

@mixin media-tablet-small() {
  @media (max-width: #{$tablet-small-width + px}) {
    @content;
  }
}

@mixin media-tablet-small-max() {
  @media (max-width: #{$tablet-small-width-max + px}) {
    @content;
  }
}

@mixin media-mobile() {
  @media (max-width: #{$mobile-width + px}) {
    @content;
  }
}
