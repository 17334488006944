.p2p__li .p2p__a-guid {
  height: 38px;
}

.content__p2p {
  .p2p__filter {
    .p2p__li a {
      justify-content: center;
      background: #2d3d524f;
    }
  }
}

@include media-tablet-large-min-max() {
  .content__p2p {
    .p2p__li a {
      font-size: 13px;
      line-height: normal;
    }

    #react-select-5-placeholder {
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }
  }
}
@include media-tablet-small-max() {
  .p2p__li-item {
    margin-bottom: 0 !important;
  }

  .content__p2p {
    .p2p__filter {
      .p2p__ul {
        flex-wrap: wrap;
        gap: 10px;
      }

      .p2p__li {
        width: calc(50% - 5px);
        padding: 0;
        margin: 0;
        flex: 0 0 48%;
        max-width: 50%;
      }

      .p2p__li a {
        padding: 10px;
        justify-content: center;
        width: 100%;

        span {
          flex: none;
        }
      }
    }

    .p2p__filter-bottom {
      > .row > div:first-child {
        max-width: 100%;
      }

      .p2p__type {
        width: 100%;
      }

      .p2p__type-buy-btn,
      .p2p__type-sell-btn {
        width: 100%;
        text-align: center;
      }
      .p2p__actions {
        margin-bottom: 20px;
      }
    }
  }
}
