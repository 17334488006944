.calculator__banner {
  position: relative;
  display: flex;
  align-items: center;
  border-radius: 10px;
  margin-bottom: 20px;
  overflow: hidden;
  height: 230px;
}
#close .calculator__img,
#save .calculator__img {
  justify-content: right;
  gap: 250px;
}

.calculator__img {
  position: relative;

  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 230px;

  // gap: 250px;
  width: 100%;
}
.calculator__img_lk {
  gap: 0px !important;
  justify-content: space-between !important;
}

.calculator-anim {
  flex-shrink: 0;
}

.calculator__description {
  width: 600px;

  .calculator__description-text {
    font-size: 18px;
    line-height: 1.2;
  }
}

.calculator__description-mobile {
  display: none;

  .calculator__description-text {
    font-size: 18px;
    line-height: 1.2;
  }
}

#hard {
  .calculator__banner {
    padding: 20px;
    background: linear-gradient(105.99deg, #0072ff 29.67%, #064da6 79.43%);

    .calculator__description {
      right: -5px;
    }
  }
}

#close {
  .calculator__img {
    object-fit: cover;
    width: 100%;

    background: url('../../../../public/assets/img/calculator-banner-2.png') no-repeat,
      linear-gradient(180deg, #81a2cb 0%, #b5d7e0 50%, #c8dbdc 96.87%) 100% / cover no-repeat;

    .calculator__description {
      .description {
        color: black;
      }
    }
  }
}

#save {
  .calculator__img {
    background: url('../../../../public/assets/img/calculator-banner-4.png') no-repeat left 100% / cover;

    .calculator__description {
      .description {
        font-size: 16px;
        color: black;
      }
    }
  }
}

#buy {
  .calculator__img {
    .calculator__description {
      .calculator__h3 {
        font-size: 30px;
      }

      .description {
        font-size: 16px;
      }
    }
  }
}

#calc {
  .calculator__img {
    .calculator__description {
      width: 500px;
    }
  }
}

//768 & 1199
@include media-tablet-large-min-max() {
  #hard .calculator__img .calculator__description {
    width: 500px;
  }

  #close .calculator__img .calculator__description {
    width: 450px;
  }

  #save .calculator__img {
    background: linear-gradient(rgba(255, 255, 255, 0.5) 100%, rgba(255, 255, 255, 0.5) 100%),
      url('../../../../public/assets/img/calculator-banner-4.png') no-repeat left 100% / cover;

    .calculator__description {
      width: 515px;

      .calculator__h3 {
        font-size: 30px;
      }
      .calculator__description-text {
        font-size: 14px;
      }
    }
  }
}

//992px
@include media-tablet-large() {
  #hard .calculator__img .calculator__description {
    width: 350px;
  }

  #close .calculator__img .calculator__description {
    width: 250px;
  }

  #save .calculator__img .calculator__description {
    .calculator__h3 {
      font-size: 30px;
    }

    .calculator__description-text {
      font-size: 14px;
    }
  }

  #buy .calculator__img .calculator__description {
    width: 450px;

    .calculator__h3 {
      font-size: 25px;
    }
    .calculator__description-text {
      font-size: 12px;
    }
  }

  #buy-2 .calculator__img .calculator__description {
    width: 350px;
  }

  #study {
    .calculator__img {
      .calculator__description {
        width: 350px;
        .calculator__h3 {
          font-size: 25px;
        }
      }
    }
  }

  #free .calculator__img .calculator__description {
    width: 550px;

    .calculator__h3 {
      font-size: 30px;
    }
  }

  #calc .calculator__img .calculator__description {
    width: 350px;

    .calculator__h3 {
      font-size: 30px;
    }
    .description {
      font-size: 16px;
    }
  }

  .calculator__description {
    .calculator__description-text {
      font-size: 14px;
    }
  }
}

//767px
@include media-tablet-small-max() {
  #hard .calculator__img {
    justify-content: center;
  }

  .calculator__description {
    display: none;
    justify-content: flex-start;
  }

  .calculator__description-mobile {
    display: block;
  }
}
