.events__description.description {
  max-width: 90%;
}

@include media-tablet-large-min-max() {
  .content__events {
    .events__thumbnail {
      flex: none;
      max-width: 140px;
      padding: 15px;

      img {
        height: 100px;
        object-fit: cover;
      }
    }

    .events__footer {
      margin-bottom: 41px;
    }

    h2.events__h2.h2 {
      font-size: 15px;
      margin-bottom: 0;
    }
    .events__readmore {
      position: absolute;
      bottom: 41px;
      left: 140px;
    }
  }
}

@include media-tablet-small-max() {
  .events__list {
    .events__wrap {
      display: block;
    }

    h2.events__h2.h2 {
      font-size: 15px;
      margin-bottom: 2px;
    }

    .events__thumbnail {
      max-width: 100%;
      padding: 0;
      margin-bottom: 10px;

      img {
        width: 100%;
        height: auto;
      }
    }

    .events__readmore a {
      width: 100%;
      display: block;
      text-align: center;
    }
  }
}
