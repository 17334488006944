.modal__wrap.p2p__steps-modal {
  .price-rating-title-mobile,
  .form__row.price__row-mobile {
    display: none;
  }
}

.p2p__info_order-page.order-item-info-mobile {
  display: none;
}

.col-md-6.col-chose-banks {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  flex-direction: column;

  .modal__actions.create-offer-step-2 {
    margin-right: 0;
  }
}

.col-md-6.create-offer-step-3 {
  padding-bottom: 0 !important;

  .modal__actions {
    margin-right: 0 !important;
  }
}

@include media-tablet-small-max() {
  .p2p__info_order-page.order-item-info {
    display: none;
  }
  .p2p__info_order-page.order-item-info-mobile {
    display: block;
    height: auto;
    width: max-content;
  }

  .modal__wrap.p2p__steps-modal {
    .modal__actions {
      width: 100%;
      padding: 0 5px;
      margin-left: 0 !important;
      margin-right: 0 !important;
    }

    .chose-banks-media-large {
      display: none;
    }

    .chose-banks-media-small {
      display: block;
    }

    .modal__checked {
      flex-wrap: wrap;
      gap: 5px 0;

      .label span {
        display: flex;
        align-items: center;
      }
    }

    .form__row.form__qty {
      margin: 0;
    }

    .col-md-6 {
      -webkit-box-flex: 0;
      -ms-flex: 0 0 50%;
      flex: 0 0 50%;
      max-width: 50%;

      .modal__actions {
        bottom: 0;
        right: 0;
      }

      .modal__actions.create-offer-step-2 {
        position: static;
      }
    }

    .col-md-12 + .col-md-6 + .col-md-6:nth-child(5) {
      padding-bottom: 70px;
    }

    .col-md-6:nth-child(5) {
      flex: 0 0 100%;
      max-width: 100%;

      .modal__actions {
        width: 100%;
        flex-direction: column;
      }

      .modal__actions button.modal__btn.btn.background__blue-two {
        margin-bottom: 10px;
      }
    }

    .col-md-6:nth-child(4) {
      flex: 0 0 100%;
      max-width: 100%;

      .modal__h6.h6:nth-child(3) {
        position: absolute;
        margin-top: 70px;
      }
    }

    .modal__faq {
      margin-bottom: 80px;
    }

    .price-rating-col-mobile {
    }

    .price-rating-title-mobile,
    .form__row.price__row-mobile {
      display: block;

      input {
        text-align: center;
      }
    }

    .price-rating-title,
    .form__row.price__row {
      display: none;
    }
  }

  .p2p__steps-item.p2p__steps-active {
    .p2p__steps-number {
      background: #0072ff;
      border: 1px solid #0072ff;
      font-size: 20px;
      text-align: center;
      position: relative;
      z-index: 2;
    }
  }

  .modal__wrap.p2p__steps-modal .p2p__steps-line,
  .p2p__buy-step .p2p__steps-line {
    position: absolute;
    width: 70%;
    transform: translate(-50%, 0);
    left: 50%;
    margin-top: -40px;
  }

  .p2p__steps {
    overflow: hidden;
    position: relative;

    .p2p__steps-name {
      font-size: 8px;
      font-style: normal;
      font-weight: 600;
      line-height: normal;
      text-align: center;
    }

    .p2p__steps-number {
      position: relative;
      z-index: 2;
      background: #2d3d52;
    }

    .p2p__steps-item {
      background: none !important;
      border: 0 !important;
      text-align: center;
      width: 33.3%;
      max-width: 33.3%;
      flex: 0 0 33.3%;
    }
  }

  .profile__security {
    .modal__content {
      .form__row.file__row {
        display: block;
      }

      .form__row-content {
        margin-top: 20px;
      }

      .file__type {
        display: block;

        .modal__btn.btn {
          margin-top: 10px;
          display: block;
          text-align: center;
        }
      }

      .form__row input {
        margin: 0;
      }

      .modal__actions {
        display: block;

        button {
          width: 100%;
          margin-top: 10px;
        }
      }

      .modal__qr-final.qr-final_bottom {
        word-break: break-all;
        max-width: 100%;
        padding: 0;
        text-align: left;
      }
    }
  }
}
