.p2p__frame-wrap .p2p__list-h6.h6 span {
  margin-left: 10px;
  display: flex;
  align-items: center;
  flex: none;
}

@include media-tablet-large-min-max() {
  .orders__main {
    .p2p__frame-wrap {
      .p2p__frame-item {
        height: 119px;
      }

      .p2p__frame-wrap .p2p__list-h6.h6 {
        font-size: 9px;
      }
    }
  }
}

@include media-tablet-small-max() {
  .filter-create-offer-btn {
    display: none;
  }
}
